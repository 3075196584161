import React from "react";
import SEO from "../seo";
import NotFound from "../not-found";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "../../scss/pages/404.scss";

const NotFoundVideoMessage = ({ data }) => {
    const { datoCmsNotFoundPage } = data;
    return (
        <>
            <SEO title="404: Not found" />
            <Helmet>
                <body className="pageNotFound" />
            </Helmet>

            <NotFound
                data={datoCmsNotFoundPage}
                locale={datoCmsNotFoundPage.locale}
                isVideoError={true}
            />
        </>
    );
};

export default NotFoundVideoMessage;

export const query = graphql`
    query notfoundVideoMessageData($locale: String!) {
        datoCmsNotFoundPage(locale: { eq: $locale }) {
            alternativeLinks {
                ... on DatoCmsArticle {
                    id
                    menuTitle
                    slug
                }
            }
            locale
            titleH1
            descriptionVideoError
            mapTextLabel
            homeTextLabel
        }
    }
`;
